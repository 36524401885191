import {memo, useCallback, useMemo} from 'react'
import {Dayjs, dayjs} from 'src/utils/date'
import DateRangeCalendarHeader from './DateRangeCalendarHeader'
import DateRangeCalendarNavbar from './DateRangeCalendarNavbar'
import DateRangeCalendarDay from 'src/components/elements/dateRangeCalendar/dateRangeCalendarDay/DateRangeCalendarDay'
import {
  CalendarDayDataType,
  DatesStatusMap,
  DatesVariantMap,
} from 'src/types/form'
import {SelectDateProps} from 'src/types/utils'
import {
  DATE_STATUS_ENABLED,
  DATE_STATUS_PAST,
  getDaysBetweenDates,
  VariantType,
} from './data'

function DateRangeCalendarBody(props: {
  datesStatusMap?: DatesStatusMap
  showPrice?: boolean
  monthDate: string
  small?: boolean
  left?: boolean
  right?: boolean
  selectMode?: boolean
  onNext: () => void
  onPrevious: () => void
  selectedDates: SelectDateProps
  setSelectedDates: (values: SelectDateProps) => void
  halfDayPicker?: boolean
  canSelectSameDay: boolean
}) {
  const {
    showPrice,
    monthDate,
    small,
    left,
    right,
    selectMode,
    onNext,
    onPrevious,
    setSelectedDates,
    selectedDates,
    datesStatusMap,
    halfDayPicker,
    canSelectSameDay,
  } = props

  const daysInMonth = useMemo(() => {
    return dayjs(monthDate, 'YYYY-MM').daysInMonth()
  }, [monthDate])

  const startDayOfMonth = useMemo(() => {
    return dayjs(monthDate, 'YYYY-MM').startOf('month').day()
  }, [monthDate])

  const endDayOfMonth = useMemo(() => {
    return dayjs(monthDate, 'YYYY-MM').endOf('month').day()
  }, [monthDate])

  const datesVariantMap = useMemo(() => {
    let map: DatesVariantMap = {}

    if (!selectedDates[0]) {
      return map
    }

    if (!selectedDates[1]) {
      map[selectedDates[0]?.format('YYYY-MM-DD')] = 'start'
      return map
    }

    //add start
    map[selectedDates[0]?.format('YYYY-MM-DD')] = 'start'

    //add middle
    let days = getDaysBetweenDates(selectedDates[0], selectedDates[1])

    days.forEach((day, index: number) => {
      if (index === 0) return //remove start
      if (days.length - 1 === index) return //remove start
      map[day.format('YYYY-MM-DD')] = 'middle'
    })

    //add end
    map[selectedDates[1]?.format('YYYY-MM-DD')] = 'end'

    return map
  }, [selectedDates])

  const localDatesStatusMap: DatesStatusMap = useMemo(() => {
    if (!datesStatusMap) return {}

    let copyDatesStatusMap: DatesStatusMap = {
      ...datesStatusMap,
    }
    if (!selectedDates) return copyDatesStatusMap
    if (!selectedDates[0]) return copyDatesStatusMap
    if (selectedDates[1]) return copyDatesStatusMap

    let firstDayBlockedDate: Dayjs | null = null

    //blocked all dates over the first blocked one since that impossible to book
    //use for let IN so its the key, using Object.keys was slow a bit
    for (let key in copyDatesStatusMap) {
      if (
        firstDayBlockedDate === null &&
        selectedDates[0]?.isBefore(copyDatesStatusMap[key].date, 'day') &&
        copyDatesStatusMap[key].status !== DATE_STATUS_ENABLED
      ) {
        firstDayBlockedDate = copyDatesStatusMap[key].date
      }

      //mark pass day as past
      if (selectedDates[0]?.isAfter(copyDatesStatusMap[key].date, 'day')) {
        copyDatesStatusMap[key] = {
          price: copyDatesStatusMap[key].price,
          date: copyDatesStatusMap[key].date,
          status: DATE_STATUS_PAST,
        }
      }

      if (
        firstDayBlockedDate !== null &&
        firstDayBlockedDate.isBefore(copyDatesStatusMap[key].date)
      ) {
        copyDatesStatusMap[key] = {
          price: copyDatesStatusMap[key].price,
          date: copyDatesStatusMap[key].date,
          status: DATE_STATUS_PAST,
        }
      }
    }

    return copyDatesStatusMap
  }, [datesStatusMap, selectedDates])

  //YYYY-MM-DD
  const daysToRender = useMemo(() => {
    const result: string[][] = []
    const item: string[] = []

    for (
      let i = 7 - startDayOfMonth + 1;
      i <= daysInMonth - endDayOfMonth;
      i++
    ) {
      let dateFormatted = `${monthDate}-${i < 10 ? `0${i}` : i}`
      if ((i + startDayOfMonth - 1) % 7 === 0) {
        item.splice(0, item.length)
        //create format YYYY-MM-DD, make sure to leftPad with 0
        item.push(dateFormatted)
        continue
      }

      item.push(dateFormatted)
      if ((i + startDayOfMonth) % 7 === 0) {
        result.push([...item])
      }
    }
    return result
  }, [monthDate, startDayOfMonth, endDayOfMonth, daysInMonth])

  const onClickDate = useCallback(
    (date: string) => {
      if (!selectMode) {
        return
      }

      let dateDayJs = dayjs(date, 'YYYY-MM-DD')

      if (!selectedDates) {
        setSelectedDates([dateDayJs, null])
        return
      }

      if (selectedDates[0] && selectedDates[1]) {
        setSelectedDates([dateDayJs, null])
        return
      }

      if (!selectedDates[0] && !selectedDates[1]) {
        setSelectedDates([dateDayJs, null])
        return
      }

      if (dateDayJs.isBefore(selectedDates[0])) {
        setSelectedDates([dateDayJs, null])
        return
      }

      if (!canSelectSameDay) {
        //if we selected the same day, reset
        if (dateDayJs.isSame(selectedDates[0], 'day')) {
          setSelectedDates([null, null])
          return
        }
      }

      setSelectedDates([selectedDates[0], dateDayJs])
    },
    [canSelectSameDay, setSelectedDates, selectMode, selectedDates],
  )

  return (
    <div>
      <DateRangeCalendarNavbar
        small={small}
        left={left}
        right={right}
        monthDate={monthDate}
        onNext={onNext}
        onPrevious={onPrevious}
      />
      <DateRangeCalendarHeader />
      <div className={`w-full ${selectMode ? '' : 'pointer-events-none'}`}>
        <div className="flex flex-row justify-start items-center w-full">
          <BlankDays count={startDayOfMonth} />
          <FirstWeeks
            datesStatusMap={localDatesStatusMap}
            datesVariantMap={datesVariantMap}
            showPrice={showPrice}
            startDayOfMonth={startDayOfMonth}
            monthDate={monthDate}
            onClickDate={onClickDate}
            halfDayPicker={halfDayPicker}
          />
        </div>
        {daysToRender.map((days: string[], index: number) => {
          return (
            <div
              className="flex flex-row justify-start items-center w-full"
              key={index}
            >
              {days.map((dateFormatted: string, dayIndex: number) => {
                let data: CalendarDayDataType | undefined = localDatesStatusMap
                  ? localDatesStatusMap[dateFormatted]
                  : undefined

                let variant: VariantType | undefined | null = datesVariantMap
                  ? datesVariantMap[dateFormatted]
                  : undefined

                //if (!data) return null

                return (
                  <DateRangeCalendarDay
                    key={dayIndex}
                    showPrice={showPrice}
                    date={dateFormatted}
                    status={data?.status}
                    price={data?.price}
                    variant={variant}
                    halfDayPicker={halfDayPicker}
                    onClick={onClickDate}
                  />
                )
              })}
            </div>
          )
        })}
        <div className="flex flex-row justify-start items-center w-full">
          <LastWeeks
            datesStatusMap={localDatesStatusMap}
            datesVariantMap={datesVariantMap}
            showPrice={showPrice}
            daysInMonth={daysInMonth}
            endDayOfMonth={endDayOfMonth}
            monthDate={monthDate}
            onClickDate={onClickDate}
            halfDayPicker={halfDayPicker}
          />
          <BlankDays count={6 - endDayOfMonth} />
        </div>
        {/* Add last row if necessary to stop calendar shifting*/}
        {daysToRender.length === 3 && (
          <div className="flex flex-row justify-start items-center w-full">
            <BlankDays count={7} />
          </div>
        )}
      </div>
    </div>
  )
}

const BlankDays = memo(function BlankDays(props: {count: number}) {
  const {count} = props
  const items: number[] = []
  for (let i = 0; i < count; i++) {
    items.push(i)
  }

  return (
    <>
      {items.map((_, i: number) => {
        return <DateRangeCalendarDay key={i} />
      })}
    </>
  )
})

const FirstWeeks = memo(function FirstWeeks(props: {
  startDayOfMonth: number
  monthDate: string
  datesStatusMap?: DatesStatusMap
  datesVariantMap?: DatesVariantMap
  onClickDate: (date: string) => void
  halfDayPicker?: boolean
  showPrice?: boolean
}) {
  const {
    showPrice,
    startDayOfMonth,
    monthDate,
    onClickDate,
    datesStatusMap,
    datesVariantMap,
    halfDayPicker,
  } = props

  const days: {
    data: CalendarDayDataType | undefined
    variant: VariantType | undefined | null
    date: string
  }[] = []
  for (let i = 0; i < 7 - startDayOfMonth; i++) {
    let day = i + 1
    let dateFormatted = `${monthDate}-${day < 10 ? `0${day}` : day}`
    let data: CalendarDayDataType | undefined = datesStatusMap
      ? datesStatusMap[dateFormatted]
      : undefined
    let variant: VariantType | undefined | null = datesVariantMap
      ? datesVariantMap[dateFormatted]
      : undefined

    //if (!data) return null
    days.push({
      date: dateFormatted,
      data,
      variant,
    })
  }

  return (
    <>
      {days.map((item, index: number) => {
        return (
          <DateRangeCalendarDay
            key={index}
            showPrice={showPrice}
            date={item.date}
            status={item.data?.status}
            price={item.data?.price}
            variant={item.variant}
            halfDayPicker={halfDayPicker}
            onClick={onClickDate}
          />
        )
      })}
    </>
  )
})

const LastWeeks = memo(function LastWeeks(props: {
  daysInMonth: number
  endDayOfMonth: number
  monthDate: string
  value?: SelectDateProps
  datesStatusMap?: DatesStatusMap
  datesVariantMap?: DatesVariantMap
  onClickDate: (date: string) => void
  halfDayPicker?: boolean
  showPrice?: boolean
}) {
  const {
    showPrice,
    daysInMonth,
    endDayOfMonth,
    monthDate,
    datesStatusMap,
    onClickDate,
    halfDayPicker,
    datesVariantMap,
  } = props

  const days: {
    data: CalendarDayDataType | undefined
    variant: VariantType | undefined | null
    date: string
  }[] = []
  for (let i = daysInMonth - endDayOfMonth; i <= daysInMonth; i++) {
    let dateFormatted = `${monthDate}-${i < 10 ? `0${i}` : i}`
    let data: CalendarDayDataType | undefined = datesStatusMap
      ? datesStatusMap[dateFormatted]
      : undefined
    let variant: VariantType | undefined | null = datesVariantMap
      ? datesVariantMap[dateFormatted]
      : undefined

    // if (!data) return null

    days.push({
      date: dateFormatted,
      data,
      variant,
    })
  }

  return (
    <>
      {days.map((item, index: number) => {
        return (
          <DateRangeCalendarDay
            key={index}
            showPrice={showPrice}
            date={item.date}
            status={item.data?.status}
            price={item.data?.price}
            variant={item.variant}
            halfDayPicker={halfDayPicker}
            onClick={onClickDate}
          />
        )
      })}
    </>
  )
})

export default memo(DateRangeCalendarBody)
