import {makeStyles} from '@material-ui/core/styles'
import DateRangeCalendarBody from './DateRangeCalendarBody'
import {useScreenSizeDown} from 'src/hooks/screenSize'
import {DatesStatusMap} from 'src/types/form'
import {SelectDateProps} from 'src/types/utils'
import {useEffect} from 'react'
import {usePrevious} from 'src/hooks/other'
import {dayjs} from 'src/utils/date'
interface StyleProps {
  vertical?: boolean
}

//todo convert to styles.module.css or tailwind utility classes
const useStyles = makeStyles(({spacing, palette}) => ({
  container: {
    backgroundColor: palette.common.white,
    display: 'flex',
    flexDirection: ({vertical}: StyleProps) => (vertical ? 'column' : 'row'),
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: spacing(0, 2),
  },
  calendar: {
    padding: spacing(1.5, 1.5),
  },
}))

export default function DateRangeCalendar(props: {
  selectedDates: SelectDateProps
  setSelectedDates: (value: SelectDateProps) => void
  visibleMonths: string[]
  nextMonth: () => void
  previousMonth: () => void
  datesStatusMap?: DatesStatusMap
  showPrice?: boolean
  selectMode?: boolean
  disableSmallSize?: boolean
  vertical?: boolean
  scrollToTop?: () => void
  halfDayPicker?: boolean
  canSelectSameDay: boolean
}) {
  const {
    showPrice,
    selectMode,
    disableSmallSize,
    vertical,
    scrollToTop,
    visibleMonths,
    datesStatusMap,
    selectedDates,
    setSelectedDates,
    halfDayPicker,
    canSelectSameDay,
  } = props

  const previousSelectedDates: SelectDateProps | undefined =
    usePrevious(selectedDates)
  const classes = useStyles({vertical})
  const isSmallerSizeDown = useScreenSizeDown(684)

  useEffect(() => {
    if (
      scrollToTop &&
      selectedDates &&
      selectedDates[0] &&
      previousSelectedDates &&
      previousSelectedDates[0]
    ) {
      const monthDate = dayjs(selectedDates[0]).format('YYYY-MM')
      const previousMonthDate = dayjs(previousSelectedDates[0]).format(
        'YYYY-MM',
      )

      //if we change month or year, we need to scroll at the top so user don't get confuse
      if (monthDate !== previousMonthDate) {
        //todo we need to figure out a way to make props.nextMonth only go 1 month in this view
        // scrollToTop()
      }
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDates])

  if (visibleMonths.length === 0) return null

  if (isSmallerSizeDown && !disableSmallSize) {
    return (
      <div className={classes.container} id="dates_selector">
        <DateRangeCalendarBody
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          datesStatusMap={datesStatusMap}
          showPrice={showPrice}
          small
          monthDate={visibleMonths[0]}
          onNext={props.nextMonth}
          onPrevious={props.previousMonth}
          selectMode={selectMode}
          halfDayPicker={halfDayPicker}
          canSelectSameDay={canSelectSameDay}
        />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div className={classes.calendar}>
        <DateRangeCalendarBody
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          datesStatusMap={datesStatusMap}
          showPrice={showPrice}
          left
          monthDate={visibleMonths[0]}
          onNext={props.nextMonth}
          onPrevious={props.previousMonth}
          selectMode={selectMode}
          halfDayPicker={halfDayPicker}
          canSelectSameDay={canSelectSameDay}
        />
      </div>
      <div className={classes.calendar}>
        <DateRangeCalendarBody
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
          datesStatusMap={datesStatusMap}
          showPrice={showPrice}
          right
          monthDate={visibleMonths[1]}
          onNext={props.nextMonth}
          onPrevious={props.previousMonth}
          selectMode={selectMode}
          halfDayPicker={halfDayPicker}
          canSelectSameDay={canSelectSameDay}
        />
      </div>
    </div>
  )
}
